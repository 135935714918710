@import "../variables";
@import "bootstrap/scss/mixins";

.carousel-title {
    padding: 1rem 5%;
    color: $grey70;
    font-family: $main-font-family;
    font-size: 28px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 36px;
    border: none;
}

.ita-carousel-container {
    &.event-container {
        margin-top: 1.5rem;
        .product-tile{
            height: 300px;

        }
    }

    .carousel-item {
        padding: 0 4px;
    }

    .product-tile {
        height: 448px;
        .product-description-container, .product-link-container {
            display: flex !important;
        }
        .product-description {
            font-size: 14px;
            letter-spacing: 0.35px;
            line-height: 20px;
        }

        .product-tile-link {
            display: flex;
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0.39px;
            line-height: 18px;
            color: $blue
        }
    }

    @include media-breakpoint-up(md) {
        .carousel-item {
            padding: 0 15px;
        }

        .product-tile {
            overflow: hidden;
            height: 512px;
            border: none;

            .image-container {
                height: 100%;
                max-height: none;
                .tile-image{
                    &.upper {
                        object-position:  top;
                    }
                    &.bottom {
                        object-position: bottom;
                    }
                }
               
                a {
                    height: 100%;

                    picture {
                        width: 100%;
                    }

                    img {
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }

            .tile-body {
                position: absolute;
                display: block;
                left: 0;
                bottom: 10%;
                background-color: $white;
                min-width: 20%;
                max-width: 30%;
                border-radius: 0 25px 25px 0;
                padding: 1rem;
                height: auto;
                max-height: 80%;
                min-height: 40%;

                .product-link-container {
                    position: relative;
                    margin-top: 2rem;

                    .product-tile-link {
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
    }
}

.ita-carousel-award {

    display: flex;
    flex-direction: column;
    gap: 1rem;

    @include media-breakpoint-up(md) {
        margin-inline: -0.5rem;
    }

    &__slider-wrapper {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        @include media-breakpoint-up(md) {
            display: block;

            position: relative;
            padding-bottom: 4rem;
        }

        .slick-arrow {
            @include media-breakpoint-up(md) {
                position: absolute;
                bottom: 0;
                width: 2.5rem;
                height: 2.5rem;
                background-color: $white;
                box-shadow: 0px 6px 12px #00000029;
                border: none;
                border-radius: 4rem;
                outline: none;

                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-size: 1rem 1rem;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-image: url("../../images/chevron-left-primary.svg");
                    z-index: 1;
                }

                &.slick-next {
                    right: 0.5rem;

                    &::after {
                        transform: rotate(180deg);
                    }
                }

                &.slick-prev {
                    right: 64px;
                }

                &.slick-disabled {
                    background-color: $button-background;
                    box-shadow: 0px 6px 12px #0000003D;
                    pointer-events: none;

                    &::after {
                        background-image: url("../../images/chevron-left-disabled.svg");
                    }
                }
            }
        }

        .slick-dots {
            @include media-breakpoint-up(md) {
                position: absolute;
                display: flex;
                gap: 1rem;
                list-style: none;
                margin: 0;
                padding: 0;
                bottom: 0;
                left: 0.5rem;
            }

            li {
                button {
                    @include media-breakpoint-up(md) {
                        display: flex;
                        width: 1rem;
                        height: 1rem;
                        border-radius: 2.5rem;
                        background: $primary 0% 0% no-repeat padding-box;
                        opacity: 0.2;
                        font-size: 1px;
                        color: transparent;
                        border: none;
                        outline: none;
                    }
                }

                &.slick-active {
                    button {
                        @include media-breakpoint-up(md) {
                            width: 2rem;
                            opacity: 1;
                        }
                    }
                }

                &::marker {
                    @include media-breakpoint-up(md) {
                        display: none;
                    }
                }
            }
        }

        .product-tile--amadeus--small__wrapper {
            @include media-breakpoint-up(md) {
                padding: 1rem 0.5rem;
            }

            cursor: pointer;

            &.hidden {
                display: none;

                @include media-breakpoint-up(md) {
                    display: flex;
                }
            }
        }
    }

    &__show-more {
        border: 2px solid $primary;
        border-radius: 8px;
        background-color: $white;
        width: 100%;
        text-align: center;
        letter-spacing: 0.57px;
        color: $primary;
        font: normal normal bold 1rem / 1.25rem $main-font-family;
        padding: 1rem;

        @include media-breakpoint-up(md) {
            display: none;
        }
    }
}
